/* Shim for deprecated uvoAutocomplete. Only modifies html layout for compatibility */
$.fn.uvoAutocomplete = $.fn.uvoAutocomplete || function() {
  return this.each(function(_, el) {
    if (el.uvoAutocompleteShimInitialized) return;
    el.uvoAutocompleteShimInitialized = true;
    var $el = $(el);
    var $wrapper = $('<div class="uvo_pref-uvoAutocomplete-wrapper" />');
    var $icon = $('<span class="ui-icon ui-icon-triangle-1-s" style="position: absolute; cursor: pointer; right: 10px; top: 0px;" />');
    $el.wrap($wrapper);
    $el.after($icon);
    $el.addClass('uvo_pref-uvoAutocomplete-input');
    $el.css({ appearance: 'none', '::-ms-expand': 'none' });
    $icon.css({ pointerEvents: 'none' });
  })
};

/* =============================
*  NEW CALC INIT
================================ */
$.getScript('/assets/js/calc.js', function () {
  // SHORT CALC
  if ($('[data-calc-mini]').length > 0 && $('.calc-mini-json').length > 0) {
    new Calc({
      ID: '4',
      calculatorType: 'DefaultCalculator',
      format: 'json',
      options: {
        deafault: {
          deadlinesDefault: [66433, 66443, 66453, 66463, 95555],
          academicLevelDefault: 2,
          paperTypesDefault: 2,
          pagesDefault: 1,
        }
      }
    });
  }

  //CALC MINI
  if ($('[data-calc-mini]').length > 0 && $('.calc-mini-html').length > 0) {
    new Calc({
      ID: '4',
      calculatorType: 'DefaultCalculator',
      format: 'html',
      options: {
        deafault: {
          deadlinesDefault: [66433, 66443, 66453, 66463, 95555],
          academicLevelDefault: 2,
          paperTypesDefault: 2,
          pagesDefault: 1,
        }
      }
    });
  }
});
/* =============================
*  end NEW CALC INIT
================================ */
//
// document.querySelector('.toggle-menu').addEventListener('click', function() {
//   this.classList.toggle('active');
//   //$('.toggle').hide();
//   $('.toggle[id=' + $(this).attr('data-toggle') + ']').toggle();
//   return false;
// });

/**
 * Description: JS for essayforyou.com
 * Version: 1.0.0
 * Last update: 2015/05/01
 * Author: UVOCorp
 *
 * Summary:
 *
 *	0. matchMedia() polyfill
 *		- Test a CSS media type/query in JS. Authors & copyright (c) 2012: Scott Jehl, Paul Irish, Nicholas Zakas. Dual MIT/BSD license
 *	1. Respond.js
 *		- min/max-width media query polyfill.
 *	2. Custom JS for essayforyou.com
 *
 */


/* ==========================================================================
   2. Custom JS for essayforyou.com
   ========================================================================== */

/*
   Toggle list
========================================================================== */
if(document.querySelector('.pricing-page')) {
  $('#content:not(.faqContent) .toggle-list .toggle-content').addClass('hide');
  $(document).on('click', '#content:not(.faqContent) .toggle-list .toggle-link', function(){
    if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
    $(this).parent().toggleClass('active');
    $(this).next().toggleClass('hide');
    return false;
  });
}


$(function() {
    //add class "selected" for item select

    //toplink click
    $('.totop-link').click(function() {
        $('body,html').animate({
            scrollTop: 0
        }, 800);
        return false;
    });
    //toggle topmenu
    $('.toggle-menu').click(function() {
        $(this).toggleClass('active');
        //$('.toggle').hide();
        $('.toggle[id=' + $(this).attr('data-toggle') + ']').toggle();
        return false;
    });
    if ($('.topmenu-line').length > 0) {
        var tmenu = $('.topmenu-line'),
            tmenuTop = $('.topmenu-line').offset().top;
    }
    $(window).scroll(function() {
        //fixed menu
        var scrlTopPos = $(window).scrollTop(),
            windowWidth = parseInt($(window).width()) + 17;
        if (windowWidth >= 968) {
            if (scrlTopPos <= tmenuTop) {
                $(tmenu).removeClass('fixed');
            } else if (scrlTopPos > tmenuTop) {
                $(tmenu).addClass('fixed');
            }
        }
        //show to-top link
        if ($(window).scrollTop() >= 200) {
            $('.totop-link').fadeIn();
        } else {
            $('.totop-link').fadeOut();
        }
    });

    $('.show-hide').find('.hidden').hide();
    $('#inq-done-more').click(function() {
        if (!$(this).hasClass('less')) {
            $(this).parents('.show-hide').find('.hidden').slideDown(
                500);
            $(this).text('Read less').addClass('less');
        } else {
            $(this).parents('.show-hide').find('.hidden').slideUp(
                500);
            $(this).text('Read more').removeClass('less');
        }
    });
    var pl_panel = $('.plagcheckc aside#rightplagpanel').clone();
    $(pl_panel).removeAttr('id').appendTo('.plagcheckc').attr('id',
        'rightplagpanel_2');


  if ($.fn.uvoAutocomplete) {
    $('.place-your-order select').uvoAutocomplete();
    //count pages
    $.fn.counts = function(options) {
      //defaultOptions
      options = $.extend({
        minusClass: 'pageskol-left',
        minimum: 1
      }, options);
      var inputCol = $(this);
      if ($(inputCol).val() <= options.minimum) {
        $('span[class=' + options.minusClass + ']').css({
          'opacity': '0.5',
          'cursor': 'default'
        });
      } else {
        $('span[class=' + options.minusClass + ']').css({
          'opacity': '1',
          'cursor': 'pointer'
        });
      }
      $(inputCol).parent().find('span').click(function() {
        if ($(this).hasClass(options.minusClass)) {
          if ($(inputCol).val() >= options.minimum) {
            var count = parseInt($(inputCol).val()) -
              1;
            count = count < options.minimum ?
              options.minimum : count;
          } else {
            $(inputCol).val(options.minimum - 1);
            var count = parseInt($(inputCol).val()) -
              1;
            count = count < options.minimum ?
              options.minimum : count;
          }
          if ($(inputCol).val() <= (options.minimum +
              1)) {
            $('span[class=' + options.minusClass +
              ']').css({
              'opacity': '0.5',
              'cursor': 'default'
            });
          }
        } else {
          if ($(inputCol).val() >= options.minimum) {
            var count = parseInt($(inputCol).val()) +
              1;
          } else {
            $(inputCol).val(options.minimum - 1);
            var count = parseInt($(inputCol).val()) +
              1;
          }
          if ($(inputCol).val() > (options.minimum -
              1)) {
            $('span[class=' + options.minusClass +
              ']').css({
              'opacity': '1',
              'cursor': 'pointer'
            })
          }
        }
        $(inputCol).val(count);
        $(inputCol).change();
        $(this).parent().next().find('span').html(275 *
          count);
        return false;
      });
      $(this).change(function(e) {
        var count = parseInt($(this).val())
        if (isNaN(count)) {
          count = 1;
          $(this).val(1);
        }
        $(this).parent().next().find('span').html(275 *
          count);
      });
      $(this).focusout(function(e) {
        var count = parseInt($(this).val())
        if (isNaN(count)) {
          count = 1;
          $(this).val(1);
        }
        $(this).parent().next().find('span').html(275 *
          count);
      });
      $(this).keydown(function(e) {
        if (e.which != 8 && e.which != 0 && (e.which <
            37 || e.which > 57) && e.which != 96 &&
          e.which != 97 && e.which != 98 && e.which !=
          99 && e.which != 100 && e.which != 101 && e
          .which != 102 && e.which != 103 && e.which !=
          104 && e.which != 105) {
          return false;
        }
        var key, keyChar;
        if (!e) var e = window.e;
        if (e.keyCode) key = e.keyCode;
        else if (e.which) key = e.which;
        if (key == 8) {
          return true;
        }
        if (key == 38 || key == 39 || (e.which < 48 ||
            e.which > 57) && e.which != 96 && e.which !=
          97 && e.which != 98 && e.which != 99 && e.which !=
          100 && e.which != 101 && e.which != 102 &&
          e.which != 103 && e.which != 104 && e.which !=
          105) {
          if (key == 40 || key == 37) {
            if ($(this).val() >= options.minimum) {
              var count = parseInt($(this).val()) -
                1;
              count = count < options.minimum ?
                options.minimum : count;
            } else {
              $(this).val(options.minimum - 1);
              keyChar = String.fromCharCode(key);
              var count = parseInt($(this).val()) +
                1;
            }
          } else {
            if ($(this).val() >= options.minimum) {
              var count = parseInt($(this).val()) +
                1;
            } else {
              $(this).val(options.minimum - 1);
              var count = parseInt($(this).val()) +
                1;
            }
          }
          $(this).val(count);
          $(this).parent().next().find('span').html(
            275 * count);
          if ($(inputCol).val() <= (options.minimum)) {
            $('span[class=' + options.minusClass +
              ']').css({
              'opacity': '0.5',
              'cursor': 'default'
            });
          } else {
            $('span[class=' + options.minusClass +
              ']').css({
              'opacity': '1',
              'cursor': 'pointer'
            })
          }
        }
      });
    }
    //counts
    $('#input-kol').counts();

  } // $.fn.uvoAutocomplete


});

(function(window, document) {
  function init() {
    window.NotifyWidget({
      siteId: '4',
      container: '.NotifyWidget-banner'
    });
  }

  if (typeof window.NotifyWidget !== 'undefined') {
    init();
  } else {
    const script = document.createElement('script');
    script.src = '/assets/js/notifyWidget.min.js';
    script.async = true;
    script.onload = function () {
      init();
    };
    document.getElementsByTagName('head')[0].appendChild(script);
  }
})(window, document);

(function toggleListInit() {
  if (!document.querySelector('[data-page="frequently_asked_questions"]') && !document.querySelector('[data-page="money_back_guarantee"]') &&
    !document.querySelector('[data-site-hardcode]')) return false;

  function toggle_list() {
    $('.toggle-list .toggle-content').addClass('hide');
    $(document).on('click', '.toggle-list .toggle-link', function(){
      if($(this).next().hasClass('hide')){ $(this).next().slideDown(500); }else{ $(this).next().slideUp(500); }
      $(this).parent().toggleClass('active');
      $(this).next().toggleClass('hide');
      return false;
    });
  }

  window.addEventListener('contentRendered', toggle_list);

  if (document.querySelector('[data-site-hardcode]')) {
    toggle_list()
  }
})();
